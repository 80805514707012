import React, { useEffect,useState } from 'react';
import Modal from 'react-modal'
import dynamic from 'next/dynamic';
const Icon = dynamic(()=>import('../Icon/Icon'))
import { Constants } from '../../adapters/helpers/Constants';
const ObLink = dynamic(()=>import('../ObLink/ObLink'))

function RegionPreferenceBar(props) {
    const { document, extraAttributes } = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // console.log('Inside Region Pref bar');
    //console.log(document.fields.description);
    // console.log(document);

    const currentLanguage = document.fields.currentLanguage;
    const callToActionLabel = document.fields.callToActionLabel;
    var barDescription = document.fields.description.replace('${language}', currentLanguage);
        
    // const history = useHistory();
    // console.log(modalIsOpen);
    const closeModal = () => {     
        setModalIsOpen(false);
    };
    const [regionPreferenceBar, setregionPreferenceBar] = useState(false);

    const customStyles = {
        overlay: {
            display             : 'flex',
            justifyContent      : 'center',
            alignItems          : 'center',
            position            : 'fixed'
        },
        content: {
            position            : 'relative',
            inset               : '0',
            display             : 'flex',
            maxWidth            : '560px',
            textAlign           : 'center'
        }
    };

    useEffect(() => {
        if (!sessionStorage.getItem('userPreferredRegion')) {
            setregionPreferenceBar(true);
        }
    });

    const closeModalAndRedirect = (regionSelected,event) => {       
        console.log(regionSelected);
        
        if (!sessionStorage.getItem('userPreferredRegion')) {            
            sessionStorage.setItem("userPreferredRegion", regionSelected);  
            setModalIsOpen(false);
            setregionPreferenceBar(false);
        }
        else {
            console.log('found session ' + sessionStorage.getItem('userPreferredRegion'));
            setModalIsOpen(false);
            setregionPreferenceBar(false);
        }
    };

    if (true) {
        return (
            <div className="regionPreferenceBar">
                <p>{barDescription} <span className="onClick" onClick={() => setModalIsOpen(true)}>{callToActionLabel}</span></p>
                <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={closeModal} >
                    <div className="boxModal">
                        <button aria-label='close' onClick={() => setModalIsOpen(false)} className={'ob-spotlight-waitlist__close-modal'} type='button'>
                            <Icon name={Constants.close} size='2.4' />
                        </button>
                        <h1>Please select your country</h1>
                        {document.fields.regions.map((dest, index) => (
                            <ObLink href={dest.fields.callToActionLink.fields.slug} key={index} onClick={() => closeModalAndRedirect(dest.fields.country.fields.label.fields.text)} className={'ob-button countryName'} id={dest.fields.country.fields.label.fields.text}>
                                <span>{`${dest.fields.country.fields.label.fields.text}`}</span>
                            </ObLink>
                        ))}
                    </div>
                </Modal>
            </div>
        )
    } else {
        return null;
    }
}
RegionPreferenceBar.propTypes = {};
export default RegionPreferenceBar;
